.AddPostButton {
  position: fixed;
  bottom: 5%;
  right: 1%;
  background-color: blue;
  border-radius: 50%;
  font-size: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddPostButton__link {
  display: block;
  text-align: center;
  text-decoration: none;
  color: white;
}
