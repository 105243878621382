.AllPosts__categoryFilter {
  font-size: 1.2em;
}

.AllPosts__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.AllPosts__pagination {
  justify-content: center;
  /* text-align: center; */
  margin-top: 1.666em;
}
