@import 'src/styles/main';

.ShortPost {
  flex-basis: 320px;
  max-width: 320px;
  height: 100%;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 13%);
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.ShortPost__image {
  display: block;
  width: 100%;
  height: 200px;
  cursor: pointer;
}

.ShortPost__content {
  padding: 3em 3em 2em 3em;
  box-sizing: border-box;
}

.ShortPost__title {
  height: 1.35em;
  margin-block-start: 0.25em;
  margin-block-end: 0.5em;
}

.ShortPost__text {
  /* font-size: 1.2em; */
  font-size: 1.6em;
  line-height: 1.75;
  height: 7em;
  width: 100%;
  overflow-wrap: break-word;
  cursor: pointer;
  /* white-space: nowrap; */
}

// .ShortPost__link {
//   font-size: 1.2em;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 0.416em;
//   margin-bottom: 0.416em;
//   display: block;
//   width: fit-content;
//   padding: 0.8333em 1.6666em;
//   background-color: #7183b3;
//   color: #fff;
//   border-radius: 2px;
//   box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
//     0 1px 5px 0 rgb(0 0 0 / 12%);
//   text-decoration: none;
//   line-height: 1;
// }

.ShortPost__link {
  // font-size: 1.2em;
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 0.416em;
  // margin-bottom: 0.416em;
  // display: block;
  // width: fit-content;
  // padding: 0.8333em 1.6666em;
  // background-color: #7183b3;
  color: inherit;
  height: 100%;
  // border-radius: 2px;
  // box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
  //   0 1px 5px 0 rgb(0 0 0 / 12%);
  text-decoration: inherit;
  line-height: 1;
}

.ShortPost__link:hover {
  box-shadow: none;
}

/* .articleContentBody {
  font-size: 14px;
  padding-bottom: 20px;
} */

/* .articleContentHeader {
  font-size: 14px;
} */

.ShortPost__metadata {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2em;
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 30px 30px 30px; */
}

img[src=""].ShortPost--skeleton-loader:empty, :not(img).ShortPost--skeleton-loader:empty {
  @include skeleton-loader;
}

img[src=""].ShortPost--skeleton-loader:empty::after, :not(img).ShortPost--skeleton-loader:empty::after {
  @include skeleton-loader-after;
}

@media #{$break1} {
  .ShortPost__svg {
    padding-inline: 0.8em;
    margin-inline-start: 0.2em;
  }

  .ShortPost__start {
    font-size: 1.2em;
  }
}