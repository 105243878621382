.ListBox {
    font-size: 10rem;
    padding: 0;
    position: relative;
}

.ListBox__title {
    font-size: 1em;
    /* color: gray; */
    color: #7e7474;
    padding-left: 0.2em;
    padding-right: 0.2em;
}

.ListBox__select, .ListBox__select:focus {
    position: relative;
    top: -2px;
    background-color: transparent;
    border:0;
    padding: 0.03em;
    outline: none;
    min-width: 15ch;
}
